import React from "react";

import { Link } from "react-router-dom";

const PortfolioHeader = () => {
  return (
    <header>
      <div className="w-full bg-zinc-900 py-6 flex space-x-6 border-b-[1px] border-zinc-800">
        <div className="self-center text-zinc-200 space-x-6 pl-6">
          <Link className="text-lg hover:text-zinc-500 font-semibold" to="/">
            Josh Jacobs
          </Link>
        </div>
      </div>
    </header>
  );
};

export default PortfolioHeader;
