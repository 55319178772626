const PortfolioCardLink = ({ destination, label }) => {
  return (
    <a
      href={destination}
      target="_blank"
      className="h-12 p-6 text-center flex justify-center align-middle items-center rounded bg-zinc-900 hover:bg-zinc-800 drop-shadow border-zinc-700 hover:border-zinc-500 border-2 "
    >
      {label}
    </a>
  );
};

export default PortfolioCardLink;
