import React from "react";

import { Link } from "react-router-dom";
import Links from "./Links";

const renderFooterLink = (src, destination) => {
  return (
    <Link
      className="hover:opacity-100 opacity-50"
      to={destination}
      target="_blank"
    >
      <img className="w-[32px] h-[32px]" src={src} />
    </Link>
  );
};

const PortfolioFooter = () => {
  return (
    <footer className="w-full text-zinc-200  border-t-[1px] border-zinc-800 bg-zinc-900 py-6 px-6 flex justify-end space-x-6">
      <Links />
    </footer>
  );
};

export default PortfolioFooter;
