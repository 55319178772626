import React from "react";

import PortfolioHeader from "./PortfolioHeader";
import PortfolioFooter from "./PortfolioFooter";

const PortfolioLayout = ({ children }) => {
  return (
    <>
      <PortfolioHeader />
      <main className="text-zinc-200 bg-zinc-900">{children}</main>
      <PortfolioFooter />
    </>
  );
};

export default PortfolioLayout;
