import { useQuery } from "react-query";

import * as contentful from "contentful";

// Components
import ContentContainer from "../../components/ContentContainer";
import PortfolioCard from "../../components/PortfolioCard";

import Portrait from "../../assets/portrait.png";

// Types
import Links from "../../components/Links";
import { useEffect } from "react";

function Portfolio() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const client = contentful.createClient({
    space: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENT_DELIVERY_TOKEN, // PRODUCTION TOKEN
    // accessToken: process.env.REACT_APP_CONTENT_PREVIEW_TOKEN,
    // host: "preview.contentful.com",
  });

  const { data, isLoading, error } = useQuery(`entries`, () => {
    return client.getEntries().then((entries) => {
      return entries.items.sort((a, b) => {
        return new Date(b.fields.orderDate) - new Date(a.fields.orderDate);
      });
    });
  });

  if (isLoading) return "Loading...";
  if (error) return <pre>{error.message}</pre>;

  return (
    <ContentContainer>
      <section className="px-12 pb-4 pt-8 laptop:pb-18 leading-7 text-[16px] tablet:text-[20px] flex flex-col laptop:flex-row  tablet:px-12 laptop:px-32">
        <div className="rounded-full self-center pb-16">
          <img
            className="rounded-full max-w-[200px] laptop:max-w-[200px] border-4 border-zinc-700"
            src={Portrait}
          />
        </div>
        <div className="laptop:ml-20 space-y-6 font-thin">
          <h1 className="pb-4 font-medium">Hi, I'm Josh!</h1>
          <h2 className="leading-none">
            I'm a software engineer specializing in web and game development!
          </h2>
          <Links />
        </div>
      </section>
      <section>
        <div className="flex flex-col pt-6 laptop:pt-0 px-4 tablet:px-12 laptop:px-32">
          {data &&
            data.map((entry) => {
              return (
                <PortfolioCard
                  key={entry.sys.id}
                  title={entry.fields.title}
                  id={entry.sys.id}
                  summary={entry.fields.summary}
                  previewImage={entry.fields?.previewImage}
                  date={entry.fields.orderDate}
                  githubUrl={entry.fields.githubUrl}
                  steamUrl={entry.fields.steamUrl}
                  itchUrl={entry.fields.itchUrl}
                  tags={entry.fields.tags}
                />
              );
            })}
        </div>
      </section>
    </ContentContainer>
  );
}

export default Portfolio;
