import React from "react";

import { Link } from "react-router-dom";
import PortfolioCardLink from "./PortfolioCardLink";

const formatDate = (date) => {
  return new Date(date).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

const PortfolioCard = ({
  title,
  id,
  summary,
  previewImage,
  date,
  githubUrl,
  itchUrl,
  steamUrl,
  tags,
}) => {
  return (
    <div className="drop-shadow-card bg-gradient-to-tr from-zinc-800 to-zinc-700 flex mb-8 justify-between rounded p-6 pl-10">
      <div className="flex flex-col justify-center space-y-2">
        <Link
          to={`/${id}`}
          className="text-3xl laptop:text-5xl hover:underline font-semibold"
        >
          {title}
        </Link>
        <p className="text-zinc-400">{formatDate(date)}</p>
        <p className="text-2xl pr-4">{summary}</p>
        <div className="space-y-4">
          {(githubUrl || itchUrl || steamUrl) && (
            <div className="flex space-x-2 pt-4 laptop:pt-12">
              {githubUrl && (
                <PortfolioCardLink destination={githubUrl} label="Github" />
              )}
              {itchUrl && (
                <PortfolioCardLink destination={itchUrl} label="Itch.io" />
              )}
              {steamUrl && (
                <PortfolioCardLink destination={steamUrl} label="Steam" />
              )}
            </div>
          )}
          <div className="flex space-x-2 text-zinc-400">
            {tags &&
              tags.map((tag) => {
                return <p key={`${title}_tag_${tag}`}>#{tag}</p>;
              })}
          </div>
        </div>
      </div>

      <div className="hidden laptop:flex overflow-hidden rounded drop-shadow-card-image h-full w-full justify-end content-center self-center">
        <img
          className="object-cover w-96"
          src={`https:${previewImage.fields.file.url}`}
        />
      </div>
    </div>
  );
};

export default PortfolioCard;
