const Links = () => {
  const links = [
    { displayText: "Github", url: "https://github.com/Joshalexjacobs" },
    { displayText: "LinkedIn", url: "https://www.linkedin.com/in/jjacobscs/" },
    { displayText: "Itch.io", url: "https://stumpheadgames.itch.io/" },
    {
      displayText: "Steam",
      url: "https://store.steampowered.com/publisher/stumphead",
    },
  ];

  return (
    <div className="flex flex-row space-x-6 text-lg tablet:text-2xl">
      {links &&
        links.map((link, i) => {
          return (
            <a
              key={`link_${link.displayText}`}
              target="_blank"
              className="underline hover:no-underline hover:text-zinc-500 font-semibold"
              href={link.url}
            >
              {link.displayText}
            </a>
          );
        })}
    </div>
  );
};

export default Links;
