import { useParams, Link } from "react-router-dom";
import { useQuery } from "react-query";

import { Prism } from "@mantine/prism";

import format from "date-fns/format";

import * as contentful from "contentful";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

// Components
import ContentContainer from "../../../components/ContentContainer";
import { useEffect } from "react";

function PortfolioPiece() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const client = contentful.createClient({
    space: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENT_DELIVERY_TOKEN, // PRODUCTION TOKEN
    // accessToken: process.env.REACT_APP_CONTENT_PREVIEW_TOKEN,
    // host: "preview.contentful.com",
  });

  const { id } = useParams();

  const { data, isLoading, error } = useQuery(`entry_${id}`, () => {
    return client.getEntry(id).then((entry) => {
      return entry;
    });
  });

  if (isLoading) return "Loading...";
  if (error) return <pre>{error.message}</pre>;

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        switch (node.data.target.fields.file.contentType) {
          case "video/mp4":
            return (
              <video controls>
                <source
                  src={`https:${node.data.target.fields.file.url}`}
                  type="video/mp4"
                />
              </video>
            );
          case "image/png":
          case "image/gif":
          case "image/jpeg":
            return <img src={`https:${node.data.target.fields.file.url}`} />;
        }
      },
      [BLOCKS.PARAGRAPH]: (node) => {
        if (
          node.content.length === 1 &&
          node.content[0]?.marks[0]?.type == "code"
        ) {
          return (
            <Prism withLineNumbers noCopy colorScheme="dark" language="tsx">
              {node.content[0].value}
            </Prism>
          );
        }

        return (
          <p>
            {node.content &&
              node.content.map((paragraph, i) => {
                if (paragraph?.nodeType === "hyperlink") {
                  return (
                    <Link
                      className="pr-2 text-zinc-200 hover:text-zinc-500 font-medium underline hover:no-underline"
                      target="_blank"
                      to={paragraph.data.uri}
                    >
                      {paragraph.content[0].value}
                    </Link>
                  );
                }

                if (paragraph?.marks[0]?.type === "italic") {
                  return (
                    <span
                      key={`italic_${paragraph.value}_${i}`}
                      className="italic"
                    >
                      {paragraph.value}
                    </span>
                  );
                } else if (paragraph?.marks[0]?.type === "bold") {
                  return (
                    <span
                      key={`bold_${paragraph.value}_${i}`}
                      className="font-semibold"
                    >
                      {paragraph.value}
                    </span>
                  );
                } else if (paragraph?.marks[0]?.type === "code") {
                  return (
                    <code
                      key={`code_${paragraph.value}_${i}`}
                      className=" text-pink-400"
                    >
                      {paragraph.value}
                    </code>
                  );
                }

                return paragraph.value;
              })}
          </p>
        );
      },
      [BLOCKS.HEADING_2]: (node) => {
        return <h2 className="pt-[50px]">{node.content[0].value}</h2>;
      },
      [BLOCKS.UL_LIST]: (node) => {
        return (
          <ul className="list-disc pl-4">
            {node.content.map((listItem) => {
              return (
                <li key={`listItem_${listItem.content[0].content[0].value}`}>
                  {listItem.content[0].content[0].value}
                </li>
              );
            })}
          </ul>
        );
      },
    },
  };

  return (
    <section>
      {data && (
        <>
          {data?.fields?.coverImage?.fields?.file?.url && (
            <img
              className="object-cover object-center w-full max-h-64"
              src={`https:${data.fields.coverImage.fields.file.url}`}
            />
          )}
          <ContentContainer>
            <div className="px-12">
              <div>
                <h1 className="font-medium">{data.fields.title}</h1>
              </div>
              <div className="flex justify-between">
                <div className="">
                  {data.fields.role && <p>Role: {data.fields.role}</p>}
                  {data.fields.steamUrl && (
                    <Link
                      className="pr-2 text-zinc-200 hover:text-zinc-500 font-medium underline hover:no-underline"
                      to={data.fields.steamUrl}
                      target="_blank"
                    >
                      Steam
                    </Link>
                  )}
                  {data.fields.itchUrl && (
                    <Link
                      className="pr-2 text-zinc-200 hover:text-zinc-500 font-medium underline hover:no-underline"
                      to={data.fields.itchUrl}
                      target="_blank"
                    >
                      Itch.io
                    </Link>
                  )}
                  {data.fields.githubUrl && (
                    <Link
                      className="pr-2 text-zinc-200 hover:text-zinc-500 font-medium underline hover:no-underline"
                      to={data.fields.githubUrl}
                      target="_blank"
                    >
                      Github
                    </Link>
                  )}
                </div>
                <div>
                  {data.fields.orderDate && (
                    <p>
                      {format(new Date(data.fields.orderDate), "MM/dd/yyyy")}
                    </p>
                  )}
                </div>
              </div>
              <div className="py-6 space-y-8 leading-6 tablet:leading-7 laptop:leading-9 text-[20px] tablet:text-[20px] laptop:text-[26px]">
                {data.fields.youtubeUrl && (
                  <iframe
                    className="w-[300px] h-[168px] tablet:w-[450px] tablet:h-[252px] laptop:w-[600px] laptop:h-[336px] my-6"
                    src={data.fields.youtubeUrl}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                    allowFullScreen
                  ></iframe>
                )}
                {documentToReactComponents(data.fields.body, options)}
              </div>
            </div>
          </ContentContainer>
        </>
      )}
    </section>
  );
}

export default PortfolioPiece;
