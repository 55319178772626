import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Routes, Route } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";

import "./index.css";

// Pages
import Portfolio from "./pages/portfolio/portfolio";
import PortfolioPiece from "./pages/portfolio/piece/portfolioPiece";

// Layout
import PortfolioLayout from "./components/PortfolioLayout";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={
              <PortfolioLayout>
                <Portfolio />
              </PortfolioLayout>
            }
          />

          {/* Dynamic Portfolio Routes */}
          <Route
            path="/:id"
            element={
              <PortfolioLayout>
                <PortfolioPiece />
              </PortfolioLayout>
            }
          />
        </Routes>
      </HashRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
